<template>
  <div class="goods-table-wrapper">
    <v-button text="添加" @click="add" v-if="!hide" :disabled="!regionId" />
    <table-panel
      ref="goodsTable"
      :isMultiSelect="!hide"
      :headers="selectTableHeaders"
      :tableData="couponListWithComponent"
      :hasOperateColumn="false"
    />
    <v-button
      v-if="couponList.length > 0 && !hide"
      type="danger"
      @click="removeSelectedGoods"
      >删除</v-button
    >
    <multi-select
      :isShow.sync="couponListVisible"
      :searchUrl="getPromotionCouponURL"
      :backFill.sync="couponList"
      :searchParams="searchParams"
      :extraParams="extraParams"
      :isMultiSelect="true"
      :headers="headers"
      title="红包商品"
      :responseParams="{ id: 'redPacketPoolId', name: 'title' }"
      :responseOtherKey="['grantNum', 'soldNum', 'stores']"
      @callback="confirmGoodsSelect"
      :selectable="selectable"
      :appendToBody="true"
    >
      <template #searchSlot>
        <v-input label="红包库名称" v-model="searchParams.title"></v-input>
        <!-- <v-select
          label="红包类型"
          v-model="searchParams.redPacketType"
          :options="redPacketTypeOps"
        ></v-select> -->
      </template>
    </multi-select>
  </div>
</template>
<script>
import { MultiSelect, TablePanel } from "@/components/bussiness";
import { getPromotionCouponURL, getShopsURL } from "./api";
import { setRedPacketTypeOps } from "./map";
import emitter from "element-ui/src/mixins/emitter";

export default {
  mixins: [emitter],
  props: {
    value: {
      type: Array,
      required: true,
    },
    hide: {
      type: Boolean,
      required: false,
    },
    regionId: String,
    isLook: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    TablePanel,
    MultiSelect,
  },
  data() {
    let _this = this;

    return {
      disabled: this.isLook,
      redPacketTypeOps: setRedPacketTypeOps(1),
      searchParams: {
        title: "",
        redPacketType: 3,
      },
      busIdParams: {
        searchUrl: getShopsURL,
        request: {
          text: "busName",
          value: "busId",
        },
      },
      couponList: [],
      getPromotionCouponURL,
      couponListVisible: false,
      couponListWithComponent: [],
      headers: [
        {
          label: "红包类型",
          prop: "redPacketTypeName",
        },
        {
          label: "红包库名称",
          prop: "title",
        },
        {
          label: "红包用途",
          prop: "sourceName",
        },
        {
          prop: "money",
          label: "红包金额",
        },
      ],
      selectTableHeaders: [
        {
          prop: "name",
          label: "商品",
        },
        {
          prop: "explanationComponent",
          label: "兑换说明",
          width: 180,
        },
        {
          prop: "purchaseTypeComponent",
          label: "购买方式",
          width: 180,
        },
        {
          prop: "pointPriceComponent",
          label: "积分价格",
          width: 220,
        },
        {
          prop: "cashPriceComponent",
          label: "现金价格",
          width: 180,
        },
        {
          prop: "stockNumberComponent",
          label: "商品库存",
          width: 180,
        },
        {
          prop: "limitNumberComponent",
          label: "限购数量",
          width: 180,
        },
        {
          prop: "sortComponent",
          label: "排序",
          width: 180,
        },
      ],
    };
  },
  methods: {
    selectable(row, index) {
      return row.integralUsed == 0;
    },
    removeSelectedGoods() {
      let list = this.$refs.goodsTable.getSelectedData();
      let inputList = this.value.filter(
        (item) => !list.find((good) => good.id === item.id)
      );
      this.$emit("input", inputList);
      this.dispatch("ElFormItem", "el.form.change", [this.data]);
    },
    add() {
      this.couponListVisible = true;
    },
    confirmGoodsSelect(list) {
      // 选择时对上一次选择结果集里存在的元素过滤，保存上一次填写的结果
      if (list.length) {
        let ids = this.value.map((item) => item.id);
        this.couponList = list.map((item) =>
          ids.includes(item.id)
            ? this.value.find((p) => p.id === item.id)
            : item
        );
      } else {
        this.couponList = [];
      }

      // 对已选数据生成组件
      this.couponListWithComponent = this.couponList.map((item) => ({
        ...item,
      }));
      // this.couponList = list // 用来多选弹框回显的数据，数据和value为同一数据。在多次选择中，为不覆盖之前选择的商品，所以新加数据中过滤现有数组中有的。

      this.generateComponent(this.couponListWithComponent);
      // 更新数据不能和value的数组引用，数组项的引用相同，否则会引起value的watch的执行。同时整个table会重新渲染
      let inputList = this.getPromotionGoodsWithData();
      this.$emit("input", inputList);
      this.dispatch("ElFormItem", "el.form.change", [this.data]);
    },
    generateComponent(list) {
      let self = this;
      list.forEach((item, index) => {
        item.explanationComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.explanation,
              };
            },
            template: `<el-form-item prop="couponList.${index}.explanation" :rules="[{ required: true, message:'兑换说明不能为空', trigger: 'blur' }]">
              <v-input v-model="val"  /></el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "explanation", newValue);
                self.dispatch("ElFormItem", "el.form.change", [newValue]);
              },
            },
          },
        };
        item.purchaseTypeComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.purchaseType,
                options: self.purchaseTypeOptions,
              };
            },
            template: `<el-form-item prop="couponList.${index}.purchaseType" :rules="[{ required: true, message:'促销价格不能为空', trigger: 'change' }]">
              <v-select v-model="val" :options="options" :disabled="${self.disabled}"/>
            </el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "purchaseType", newValue);
                self.dispatch("ElFormItem", "el.form.change", [newValue]);
              },
            },
          },
        };
        item.pointPriceComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.pointPrice,
              };
            },
            template: `<el-form-item prop="couponList.${index}.pointPrice" :rules="[{ required: true, message:'促销价格不能为空', trigger: 'blur' }]">
              <v-input-number v-model="val" :max="9999999999" :disabled="${self.disabled}"/> 积分</el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "pointPrice", newValue);
                self.dispatch("ElFormItem", "el.form.change", [newValue]);
              },
            },
          },
        };
        item.cashPriceComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.cashPrice,
              };
            },
            template: `<el-form-item prop="couponList.${index}.cashPrice" :rules="[{ required: true, message:'促销价格不能为空', trigger: 'blur' },{ validator: validate, trigger: 'blur' }]">
              <v-input-number v-model="val" :max="9999999999.99" :precision="2" :disabled="${self.disabled}"/>
            </el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "cashPrice", newValue);
                self.dispatch("ElFormItem", "el.form.change", [newValue]);
              },
            },
            methods: {
              validate: (rule, value, callback) => {
                if (value > 0 && self.value[index].purchaseType == 0) {
                  callback(new Error("现金价格不能大于0"));
                } else {
                  callback();
                }
              },
            },
          },
        };
        item.stockNumberComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.stockNumber,
              };
            },
            computed: {
              max() {
                return Number(item.grantNum) - Number(item.soldNum);
              },
            },
            template: `
              <el-form-item prop="couponList.${index}.stockNumber" :rules="[{ required: true, message:'促销数量不能为空', trigger: 'blur' },
              { validator: validate, trigger: 'blur' }]">
                <v-input-number v-model="val" :disabled="${self.disabled}"/>
              </el-form-item>
            `,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "stockNumber", newValue);
                self.dispatch("ElFormItem", "el.form.change", [this.data]);
              },
            },
            methods: {
              validate: (rule, value, callback) => {
                if (value > Number(item.grantNum) - Number(item.soldNum)) {
                  callback(
                    new Error(
                      `已超出最大发放量：${
                        Number(item.grantNum) - Number(item.soldNum)
                      }`
                    )
                  );
                } else if (value === 0) {
                  callback(new Error(`数量不能为0`));
                } else {
                  callback();
                }
              },
            },
          },
        };
        item.limitNumberComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.limitNumber,
              };
            },
            computed: {
              stockNumber() {
                return self.value[index].stockNumber;
              },
            },
            template: `<el-form-item  prop="couponList.${index}.limitNumber" :rules="[{ required: true, message:'限购数量不能为空', trigger: 'blur' }, { validator: validate, trigger: 'blur'}]">
              <v-input-number :max="stockNumber" v-model="val" :disabled="${self.disabled}"/>
            </el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "limitNumber", newValue);
                self.dispatch("ElFormItem", "el.form.change", [this.data]);
              },
            },
            methods: {
              validate: (rule, value, callback) => {
                if (value > self.value[index].stockNumber) {
                  callback(new Error("限购数量不能大于促销数量"));
                } else {
                  callback();
                }
              },
            },
          },
        };
        item.sortComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.sort,
              };
            },
            template: `<el-form-item prop="couponList.${index}.sort" :rules="[{ required: true, message:'排序不能为空', trigger: 'blur' }]">
              <v-input-number :max="99" v-model="val" :disabled="${self.disabled}"/>
            </el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "sort", newValue);
                self.dispatch("ElFormItem", "el.form.change", [this.data]);
              },
            },
          },
        };
      });
    },
    getPromotionGoodsWithData() {
      return this.couponListWithComponent.map((item) => {
        return {
          id: item.id,
          name: item.name,
          purchaseType: item.purchaseType,
          cashPrice: item.cashPrice,
          grantNum: item.grantNum,
          soldNum: item.soldNum,
          stockNumber: item.stockNumber,
          limitNumber: item.limitNumber,
          sort: item.sort,
          pointPrice: item.pointPrice,
          explanation: item.explanation,
          storeId: item.stores[0].storeId,
          goodsId: item.id
        };
      });
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.couponListWithComponent = newVal.map((item) => ({ ...item }));
        this.couponList = newVal.map((item) => ({ ...item }));
        // 更新输入框的值
        this.generateComponent(this.couponListWithComponent);
      } else {
        this.couponListWithComponent = [];
        this.couponList = [];
      }
    },
  },
  computed: {
    purchaseTypeOptions() {
      let ops = [
        { text: "积分", value: 0 },
        // { text: "积分+现金", value: 1 },
      ];
      return ops;
    },
    extraParams() {
      return {
        source: 10,
        regionId: this.regionId,
      };
    },
  },
};
</script>

<style lang="scss">
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>
