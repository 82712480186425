var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: {
            form: _vm.form,
            queryUrl: _vm.queryUrl,
            submitUrl: _vm.submitUrl,
            submitBefore: _vm.submitBefore,
          },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-block",
                { attrs: { title: "" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "运营主体",
                        prop: "regionId",
                        rules: [
                          {
                            required: true,
                            message: "请选择运营主体",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "输入运营主体名称",
                              disabled: _vm.isView || _vm.isUpdate,
                            },
                            on: { onChange: _vm.regionChange },
                            model: {
                              value: _vm.form.regionId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "regionId", $$v)
                              },
                              expression: "form.regionId",
                            },
                          },
                          "v-select2",
                          _vm.regionParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属类型",
                        prop: "type",
                        rules: [
                          {
                            required: true,
                            message: "当前选项不允许为空",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.ptoductTypeOps,
                          disabled: _vm.isView || _vm.isUpdate,
                        },
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.type == 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择商品",
                            rules: [{ required: true }],
                          },
                        },
                        [
                          _c("promot-goods-select", {
                            attrs: {
                              hide: _vm.isView || _vm.isUpdate,
                              regionId: _vm.form.regionId,
                              isLook: !!_vm.isView,
                            },
                            model: {
                              value: _vm.form.promotionGoods,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "promotionGoods", $$v)
                              },
                              expression: "form.promotionGoods",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.type == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择卡券红包",
                            rules: [{ required: true }],
                          },
                        },
                        [
                          _c("promot-coupon-select", {
                            attrs: {
                              hide: _vm.isView || _vm.isUpdate,
                              regionId: _vm.form.regionId,
                              isLook: !!_vm.isView,
                            },
                            model: {
                              value: _vm.form.couponList,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "couponList", $$v)
                              },
                              expression: "form.couponList",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上下架时间段", prop: "time" } },
                    [
                      _c("v-datepicker2", {
                        attrs: {
                          startTime: _vm.form.time.beginTime,
                          endTime: _vm.form.time.endTime,
                          minuteStep: 10,
                          minDate: new Date(),
                          type: "rangedatetimer",
                          disabled: _vm.isView,
                          endDisabled: !_vm.form.time.beginTime,
                          appendToBody: true,
                        },
                        on: {
                          "update:startTime": function ($event) {
                            return _vm.$set(_vm.form.time, "beginTime", $event)
                          },
                          "update:start-time": function ($event) {
                            return _vm.$set(_vm.form.time, "beginTime", $event)
                          },
                          "update:endTime": function ($event) {
                            return _vm.$set(_vm.form.time, "endTime", $event)
                          },
                          "update:end-time": function ($event) {
                            return _vm.$set(_vm.form.time, "endTime", $event)
                          },
                          schange: _vm.beginTimeChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "产品上下架状态",
                        prop: "status",
                        rules: [{ required: true }],
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-text": "上架",
                          "inactive-text": "下架",
                          disabled: _vm.isView,
                        },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否开启叠加活动",
                        prop: "overType",
                        rules: [{ required: true }],
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 0,
                          "inactive-value": 1,
                          "active-color": "#67C23A",
                          disabled: _vm.isView,
                        },
                        model: {
                          value: _vm.form.overType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "overType", $$v)
                          },
                          expression: "form.overType",
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v("开启后，积分商品可同时参加其他营销活动"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }