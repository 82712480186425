// 获取列表
const getListURL = `${API_CONFIG.shopBaseURL}sysStoreIntegralGoods/list`
// 获取详情
const getDetailURL = `${API_CONFIG.shopBaseURL}sysStoreIntegralGoods/detail`
// 批量上架操作
const batchOnlineURL = `${API_CONFIG.baseURL}bonusGoodAction!online.action`
// 批量下架操作
const batchOfflineURL = `${API_CONFIG.baseURL}bonusGoodAction!offline.action`
// 删除积分商品
const deleteURL = `${API_CONFIG.shopBaseURL}sysStoreIntegralGoods/delete`
// 新增商品
const saveURL = `${API_CONFIG.shopBaseURL}sysStoreIntegralGoods/add`
// 编辑商品
const editURL = `${API_CONFIG.shopBaseURL}sysStoreIntegralGoods/update`
// 获取红包数据
const getlRedPacketListURL = `${API_CONFIG.shopBaseURL}redPacketPoolShu/redPacketPoolSelectTo`
// 上传图片
const uploadImgURL = `${API_CONFIG.uploadURL}?module=order`
// 获取所属商家列表
const getBusinessListURL = `${API_CONFIG.baseURL}serverCodewordAction!getShops.action`
// 获取所属门店列表
const getStoreListURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!viewStoreByShop.action`
// 促销商品查询
const getPromotionGoodsURL = `${API_CONFIG.shopBaseURL}storeProduct/getAdminStoreProductList`
// 获取商家名称Select2
const getShopsURL = `${API_CONFIG.baseURL}serverCodewordAction!getShops.action`
// 获取红包列表
const getPromotionCouponURL = `${API_CONFIG.shopBaseURL}redPacketPoolShu/redPacketPoolByRegionId`
export {
  getListURL,
  getDetailURL,
  batchOnlineURL,
  batchOfflineURL,
  getlRedPacketListURL,
  uploadImgURL,
  getBusinessListURL,
  getStoreListURL,
  getPromotionGoodsURL,
  saveURL,
  editURL,
  getShopsURL,
  getPromotionCouponURL,
  deleteURL
}
