var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods-table-wrapper" },
    [
      !_vm.hide
        ? _c("v-button", {
            attrs: { text: "添加", disabled: !_vm.regionId },
            on: { click: _vm.add },
          })
        : _vm._e(),
      _c("table-panel", {
        ref: "goodsTable",
        attrs: {
          isMultiSelect: !_vm.hide,
          headers: _vm.selectTableHeaders,
          tableData: _vm.couponListWithComponent,
          hasOperateColumn: false,
        },
      }),
      _vm.couponList.length > 0 && !_vm.hide
        ? _c(
            "v-button",
            {
              attrs: { type: "danger" },
              on: { click: _vm.removeSelectedGoods },
            },
            [_vm._v("删除")]
          )
        : _vm._e(),
      _c("multi-select", {
        attrs: {
          isShow: _vm.couponListVisible,
          searchUrl: _vm.getPromotionCouponURL,
          backFill: _vm.couponList,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          isMultiSelect: true,
          headers: _vm.headers,
          title: "红包商品",
          responseParams: { id: "redPacketPoolId", name: "title" },
          responseOtherKey: ["grantNum", "soldNum", "stores"],
          selectable: _vm.selectable,
          appendToBody: true,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.couponListVisible = $event
          },
          "update:is-show": function ($event) {
            _vm.couponListVisible = $event
          },
          "update:backFill": function ($event) {
            _vm.couponList = $event
          },
          "update:back-fill": function ($event) {
            _vm.couponList = $event
          },
          callback: _vm.confirmGoodsSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "红包库名称" },
                  model: {
                    value: _vm.searchParams.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "title", $$v)
                    },
                    expression: "searchParams.title",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }