<template>
  <div class="goods-table-wrapper">
    <v-button text="添加" @click="add" v-if="!hide" :disabled="!regionId" />
    <table-panel
      ref="goodsTable"
      :isMultiSelect="!hide"
      :headers="selectTableHeaders"
      :tableData="promotionGoodsWithComponent"
      :hasOperateColumn="false"
    />
    <v-button
      v-if="promotionGoods.length > 0 && !hide"
      type="danger"
      @click="removeSelectedGoods"
      >删除</v-button
    >
    <multi-select
      :isShow.sync="promotionGoodsVisible"
      :searchUrl="getPromotionGoodsURL"
      :backFill.sync="promotionGoods"
      :searchParams="searchParams"
      :extraParams="extraParams"
      :isMultiSelect="true"
      :headers="headers"
      title="门店商品"
      :responseParams="{ id: 'id', name: 'productName' }"
      :responseOtherKey="[
        'productImg',
        'skuSpecification',
        'productStock',
        'storeId',
        'skuId',
        'goodsId'
      ]"
      :handleData="handleData"
      @callback="confirmGoodsSelect"
      :selectable="selectable"
      :appendToBody="true"
    >
      <template #searchSlot>
        <v-input label="商品名称" v-model="searchParams.productName" />
        <!-- <v-input label="门店名称"
                 v-model="searchParams.storeName" /> -->
        <v-select2
          label="商家名称"
          v-model="searchParams.busId"
          v-bind="busIdParams"
        />
        <v-select2
          label="门店名称"
          v-model="searchParams.storeId"
          v-bind="storeParams"
          :subjoin="storeExtra"
          :disabled="!searchParams.busId"
        ></v-select2>
      </template>
    </multi-select>
  </div>
</template>
<script>
import { MultiSelect, TablePanel } from "@/components/bussiness";
import { getPromotionGoodsURL, getShopsURL, getStoreListURL } from "./api";
import emitter from "element-ui/src/mixins/emitter";

export default {
  mixins: [emitter],
  props: {
    value: {
      type: Array,
      required: true,
    },
    hide: {
      type: Boolean,
      required: false,
    },
    regionId: String,
    isLook: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    TablePanel,
    MultiSelect,
  },
  data() {
    return {
      disabled: this.isLook,
      searchParams: {
        productName: "",
        storeId: "",
        busId: "",
      },
      busIdParams: {
        searchUrl: getShopsURL,
        request: {
          text: "busName",
          value: "busId",
        },
      },
      storeParams: {
        searchUrl: getStoreListURL,
        method: "post",
        request: {
          // 请求参数，
          text: "storeName",
          // 回显参数
          value: "id",
        },
        response: {
          text: "name",
          value: "id",
        },
      },
      promotionGoods: [],
      getPromotionGoodsURL,
      promotionGoodsVisible: false,
      promotionGoodsWithComponent: [],
      headers: [
        {
          label: "商品图片",
          prop: "productImgComponent",
        },
        {
          label: "商品名称",
          prop: "productName",
        },
        {
          label: "SKU(商品规格)",
          prop: "skuSpecification",
        },
        {
          label: "门店售价",
          prop: "productPrice",
        },
        {
          label: "门店名称",
          prop: "storeName",
        },
        {
          label: "商家名称",
          prop: "busName",
        },
      ],
      selectTableHeaders: [
        {
          prop: "productComponent",
          label: "商品",
        },
        {
          prop: "skuSpecification",
          label: "SKU（商品规格）",
        },
        {
          prop: "purchaseTypeComponent",
          label: "购买方式",
          width: 180,
        },
        {
          prop: "pointPriceComponent",
          label: "积分价格",
          width: 220,
        },
        {
          prop: "cashPriceComponent",
          label: "现金价格",
          width: 180,
        },
        {
          prop: "stockNumberComponent",
          label: "商品库存",
          width: 180,
        },
        {
          prop: "limitNumberComponent",
          label: "限购数量",
          width: 180,
        },
        {
          prop: "sortComponent",
          label: "排序",
          width: 180,
        },
      ],
    };
  },
  methods: {
    selectable(row, index) {
      console.log(this.promotionGoods,'promotionGoods')
      return row.integralUsed == 0;
    },
    removeSelectedGoods() {
      let list = this.$refs.goodsTable.getSelectedData();
      let inputList = this.value.filter(
        (item) => !list.find((good) => good.id === item.id)
      );
      this.$emit("input", inputList);
      this.dispatch("ElFormItem", "el.form.change", [this.data]);
    },
    add() {
      this.promotionGoodsVisible = true;
    },
    handleData(item) {
      item.productImgComponent = {
        component: {
          props: ["row"],
          template:
            '<img :src="row.productImg" style="width: 50px; height: 50px;" />',
        },
      };
    },
    confirmGoodsSelect(list) {
      // 选择时对上一次选择结果集里存在的元素过滤，保存上一次填写的结果
      if (list.length) {
        let ids = this.value.map((item) => item.id);
        this.promotionGoods = list.map((item) =>
          ids.includes(item.id)
            ? this.value.find((p) => p.id === item.id)
            : item
        );
      } else {
        this.promotionGoods = [];
      }

      // 对已选数据生成组件
      this.promotionGoodsWithComponent = this.promotionGoods.map((item) => ({
        ...item,
      }));
      // this.promotionGoods = list // 用来多选弹框回显的数据，数据和value为同一数据。在多次选择中，为不覆盖之前选择的商品，所以新加数据中过滤现有数组中有的。

      this.generateComponent(this.promotionGoodsWithComponent);
      // 更新数据不能和value的数组引用，数组项的引用相同，否则会引起value的watch的执行。同时整个table会重新渲染
      let inputList = this.getPromotionGoodsWithData();
      this.$emit("input", inputList);
      this.dispatch("ElFormItem", "el.form.change", [this.data]);
    },
    generateComponent(list) {
      let self = this;
      list.forEach((item, index) => {
        item.productComponent = {
          component: {
            props: ["row"],
            template:
              '<div style="text-overflow: ellipsis;overflow: hidden;"><img :src="row.productImg" style="width: 48px; height: 48px; object-fit: cover; vertical-align: middle;" />{{row.name}}</div>',
          },
        };
        item.purchaseTypeComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.purchaseType == "undefined" ? 0 : item.purchaseType,
                options: self.purchaseTypeOptions,
              };
            },
            template: `<el-form-item prop="promotionGoods.${index}.purchaseType" :rules="[{ required: true, message:'促销价格不能为空', trigger: 'change' }]">
              <v-select v-model="val" :options="options" :disabled="${self.disabled}"/>
            </el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "purchaseType", newValue);
                self.dispatch("ElFormItem", "el.form.change", [newValue]);
              },
            },
          },
        };
        item.pointPriceComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.pointPrice,
              };
            },
            template: `<el-form-item prop="promotionGoods.${index}.pointPrice" :rules="[{ required: true, message:'促销价格不能为空', trigger: 'blur' }]">
              <v-input-number v-model="val" :max="9999999999" :disabled="${self.disabled}"/> 积分</el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "pointPrice", newValue);
                self.dispatch("ElFormItem", "el.form.change", [newValue]);
              },
            },
          },
        };
        item.cashPriceComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.cashPrice,
              };
            },
            template: `<el-form-item prop="promotionGoods.${index}.cashPrice" :rules="[{ required: true, message:'促销价格不能为空', trigger: 'blur' },{ validator: validate, trigger: 'blur' }]">
              <v-input-number v-model="val" :max="9999999999.99" :precision="2" :disabled="${self.disabled}"/>
            </el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "cashPrice", newValue);
                self.dispatch("ElFormItem", "el.form.change", [newValue]);
              },
            },
            methods: {
              validate: (rule, value, callback) => {
                if (value > 0 && self.value[index].purchaseType == 0) {
                  callback(new Error("现金价格不能大于0"));
                } else {
                  callback();
                }
              },
            },
          },
        };
        item.stockNumberComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.stockNumber,
              };
            },
            computed: {
              max() {
                return Number(item.productStock);
              },
            },
            template: `
              <el-form-item prop="promotionGoods.${index}.stockNumber" :rules="[{ required: true, message:'促销数量不能为空', trigger: 'blur' },
              { validator: validate, trigger: 'blur' }]">
                <v-input-number v-model="val" :disabled="${self.disabled}"/>
              </el-form-item>
            `,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "stockNumber", newValue);
                self.dispatch("ElFormItem", "el.form.change", [this.data]);
              },
            },
            methods: {
              validate: (rule, value, callback) => {
                if (value > Number(item.productStock)) {
                  callback(
                    new Error(
                      `已超出门店最大库存量：${Number(item.productStock)}`
                    )
                  );
                } else if (value === 0) {
                  callback(new Error(`数量不能为0`));
                } else {
                  callback();
                }
              },
            },
          },
        };
        item.limitNumberComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.limitNumber,
              };
            },
            computed: {
              stockNumber() {
                return self.value[index].stockNumber;
              },
            },
            template: `<el-form-item  prop="promotionGoods.${index}.limitNumber" :rules="[{ required: true, message:'限购数量不能为空', trigger: 'blur' }, { validator: validate, trigger: 'blur'}]">
              <v-input-number :max="stockNumber" v-model="val" :disabled="${self.disabled}"/>
            </el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "limitNumber", newValue);
                self.dispatch("ElFormItem", "el.form.change", [this.data]);
              },
            },
            methods: {
              validate: (rule, value, callback) => {
                if (value > self.value[index].stockNumber) {
                  callback(new Error("限购数量不能大于促销数量"));
                } else {
                  callback();
                }
              },
            },
          },
        };
        item.sortComponent = {
          component: {
            props: ["row"],
            data() {
              return {
                val: item.sort,
              };
            },
            template: `<el-form-item prop="promotionGoods.${index}.sort" :rules="[{ required: true, message:'排序不能为空', trigger: 'blur' }]">
              <v-input-number :max="99" v-model="val" :disabled="${self.disabled}"/>
            </el-form-item>`,
            watch: {
              val(newValue) {
                self.$set(self.value[index], "sort", newValue);
                self.dispatch("ElFormItem", "el.form.change", [this.data]);
              },
            },
          },
        };
      });
    },
    getPromotionGoodsWithData() {
      return this.promotionGoodsWithComponent.map((item) => {
        return {
          id: item.id,
          name: item.name,
          purchaseType: item.purchaseType,
          cashPrice: item.cashPrice,
          productStock: item.productStock,
          stockNumber: item.stockNumber,
          limitNumber: item.limitNumber,
          sort: item.sort,
          productImg: item.productImg,
          skuSpecification: item.skuSpecification,
          pointPrice: item.pointPrice,
          storeId: item.storeId,
          skuId: item.skuId,
          goodsId: item.goodsId
        };
      });
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.promotionGoodsWithComponent = newVal.map((item) => ({ ...item }));
        this.promotionGoods = newVal.map((item) => ({ ...item }));
        // 更新输入框的值
        this.generateComponent(this.promotionGoodsWithComponent);
      } else {
        this.promotionGoodsWithComponent = [];
        this.promotionGoods = [];
      }
    },
  },
  computed: {
    purchaseTypeOptions() {
      let ops = [
        { text: "积分", value: 0 },
        { text: "积分+现金", value: 1 },
      ];
      return ops;
    },
    extraParams() {
      return {
        orderType: 1,
        productStatus: 0,
        openIntegral: 1,
        regionId: this.regionId,
      };
    },
    storeExtra() {
      let shopId = this.searchParams.busId;
      return { shopId };
    },
  },
};
</script>

<style lang="scss">
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>
