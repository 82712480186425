<template>
  <div>
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail :span="24">
        <col2-block title="">
          <el-form-item
            label="运营主体"
            prop="regionId"
            :rules="[
              { required: true, message: '请选择运营主体', trigger: 'change' },
            ]"
          >
            <v-select2
              v-model="form.regionId"
              placeholder="输入运营主体名称"
              v-bind="regionParams"
              :disabled="isView || isUpdate"
              @onChange="regionChange"
            />
          </el-form-item>
          <el-form-item
            label="所属类型"
            prop="type"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change',
              },
            ]"
          >
            <v-select
              v-model="form.type"
              :options="ptoductTypeOps"
              :disabled="isView || isUpdate"
            ></v-select>
          </el-form-item>
          <el-form-item
            label="选择商品"
            :rules="[{ required: true }]"
            v-if="form.type == 0"
          >
            <promot-goods-select
              v-model="form.promotionGoods"
              :hide="isView || isUpdate"
              :regionId="form.regionId"
              :isLook="!!isView"
            />
          </el-form-item>
          <el-form-item
            label="选择卡券红包"
            :rules="[{ required: true }]"
            v-if="form.type == 1"
          >
            <promot-coupon-select
              v-model="form.couponList"
              :hide="isView || isUpdate"
              :regionId="form.regionId"
              :isLook="!!isView"
            />
          </el-form-item>
          <el-form-item label="上下架时间段" prop="time">
            <v-datepicker2
              :startTime.sync="form.time.beginTime"
              :endTime.sync="form.time.endTime"
              :minuteStep="10"
              :minDate="new Date()"
              type="rangedatetimer"
              :disabled="isView"
              :endDisabled="!form.time.beginTime"
              @schange="beginTimeChange"
              :appendToBody="true"
            />
          </el-form-item>
          <el-form-item
            label="产品上下架状态"
            prop="status"
            :rules="[{ required: true }]"
          >
            <el-switch
              v-model="form.status"
              :active-value="1"
              :inactive-value="0"
              active-text="上架"
              inactive-text="下架"
              :disabled="isView"
            />
          </el-form-item>
          <el-form-item
            label="是否开启叠加活动"
            prop="overType"
            :rules="[{ required: true }]"
          >
            <el-switch
              v-model="form.overType"
              :active-value="0"
              :inactive-value="1"
              active-color="#67C23A"
              :disabled="isView"
            />
            <span style="margin-left: 20px"
              >开启后，积分商品可同时参加其他营销活动</span
            >
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { Col2Block, Col2Detail } from "@/components/bussiness";
import { getDetailURL, saveURL, editURL } from "./api";
import { ptoductTypeOps } from "./map";
import PromotGoodsSelect from "./PromotGoodsSelect";
import PromotCouponSelect from "./PromotCouponSelect";
import { regionParams } from "common/select2Params";
import { Switch } from "element-ui";
import moment from 'moment'
export default {
  name: "secondKillForm",
  components: {
    Col2Block,
    Col2Detail,
    PromotGoodsSelect,
    PromotCouponSelect,
    "el-switch": Switch,
  },
  data() {
    return {
      regionParams,
      queryUrl: getDetailURL,
      submitUrl: "",
      form: {
        time: {
          beginTime: "",
          endTime: "",
        },
        regionId: "",
        type: 0,
        promotionGoods: [],
        couponList: [],
        status: 1,
        overType: 1,
      },
      ptoductTypeOps: ptoductTypeOps.slice(1, ptoductTypeOps.length),
      timeRules: [
        {
          validator: (rule, value, callback) => {
            if (value) {
              let { beginTime, endTime } = value;
              if (!beginTime) {
                callback(new Error(`请选择开始时间`));
              } else if (!endTime) {
                callback(new Error(`请选择结束时间`));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
        },
        {
          required: true,
        },
      ],
      isView: false,
      isUpdate: false,
    };
  },
  created() {
    this.isView = this.$route.query.isView;
    this.form.type = this.$route.query.type;
  },
  mounted() {
    let { id, isView } = this.$route.query;
    if (id) {
      this.$refs.form.getData({ id });
      this.$setBreadcrumb("编辑");
      this.form.id = id;
      if (isView) {
        this.$setBreadcrumb("查看");
      } else {
        this.submitUrl = editURL;
        this.isUpdate = true;
      }
    } else {
      this.$setBreadcrumb("新增");
      this.submitUrl = saveURL;
    }
  },
  methods: {
    update(data) {
      this.form.regionId = data.regionId;
      this.form.type = data.type;
      this.form.status = data.status;
      this.form.overType = data.overType;
      this.form.time.beginTime = data.beginTime == null?'':data.beginTime;
      this.form.time.endTime = data.endTime== null?'':data.endTime;
      let keyType = data.type == 0 ? "promotionGoods" : "couponList";
      this.form[keyType] = [data];
      this.form[keyType][0].productStock = data.storesStockNumber;
      this.form[keyType][0].name = data.goodsName;
      if(data.type == 1){
        this.form[keyType][0].grantNum = data.storesStockNumber;
        this.form[keyType][0].soldNum = 0;
      }
      
    },
    submitBefore(data) {
      let keyType = "promotionGoods";
      if (data.type == 0) {
        if (!this.form.promotionGoods.length) {
          this.$message("请选择商品");
          return false;
        }
      } else {
        keyType = "couponList";
        if (!this.form.couponList.length) {
          this.$message("请选择卡券红包");
          return false;
        }
      }

      if (this.isUpdate) {
        data.beginTime = this.form.time.beginTime;
        data.endTime = this.form.time.endTime;
        data.cashPrice = data[keyType][0].cashPrice;
        data.explanation = data[keyType][0].explanation;
        data.goodsId = data[keyType][0].goodsId;
        data.goodsSkuId = data[keyType][0].goodsSkuId;
        data.limitNumber = data[keyType][0].limitNumber;
        data.pointPrice = data[keyType][0].pointPrice;
        data.purchaseType = data[keyType][0].purchaseType;
        data.sort = data[keyType][0].sort;
        data.stockNumber = data[keyType][0].stockNumber;
        data.storeId = data[keyType][0].storeId;
        delete data.time;
        delete data.promotionGoods;
        delete data.couponList;
        return true;
      } else {
        for (var key in data) {
          delete data[key];
        }

        data = this.form[keyType].map((item, index) => {
          return {
            beginTime: this.form.time.beginTime,
            endTime: this.form.time.endTime,
            cashPrice: item.cashPrice,
            explanation: item.explanation,
            goodsId: item.goodsId,
            goodsSkuId: item.skuId,
            limitNumber: item.limitNumber,
            pointPrice: item.pointPrice,
            purchaseType: item.purchaseType,
            sort: item.sort,
            stockNumber: item.stockNumber,
            storeId: item.storeId,
            regionId: this.form.regionId,
            status: this.form.status,
            overType: this.form.overType,
            type: this.form.type,
          };
        });
        return data;
      }
    },
    regionChange(data, echo) {
      if (!echo) {
        this.form.promotionGoods = [];
        this.form.couponList = [];
      }
    },
    beginTimeChange(val) {
      if(val){
        if(moment(this.form.time.beginTime).isAfter(this.form.time.endTime)){
          this.form.time.endTime = ''
        }
      }
    }
  },
};
</script>
