var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods-table-wrapper" },
    [
      !_vm.hide
        ? _c("v-button", {
            attrs: { text: "添加", disabled: !_vm.regionId },
            on: { click: _vm.add },
          })
        : _vm._e(),
      _c("table-panel", {
        ref: "goodsTable",
        attrs: {
          isMultiSelect: !_vm.hide,
          headers: _vm.selectTableHeaders,
          tableData: _vm.promotionGoodsWithComponent,
          hasOperateColumn: false,
        },
      }),
      _vm.promotionGoods.length > 0 && !_vm.hide
        ? _c(
            "v-button",
            {
              attrs: { type: "danger" },
              on: { click: _vm.removeSelectedGoods },
            },
            [_vm._v("删除")]
          )
        : _vm._e(),
      _c("multi-select", {
        attrs: {
          isShow: _vm.promotionGoodsVisible,
          searchUrl: _vm.getPromotionGoodsURL,
          backFill: _vm.promotionGoods,
          searchParams: _vm.searchParams,
          extraParams: _vm.extraParams,
          isMultiSelect: true,
          headers: _vm.headers,
          title: "门店商品",
          responseParams: { id: "id", name: "productName" },
          responseOtherKey: [
            "productImg",
            "skuSpecification",
            "productStock",
            "storeId",
            "skuId",
            "goodsId",
          ],
          handleData: _vm.handleData,
          selectable: _vm.selectable,
          appendToBody: true,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.promotionGoodsVisible = $event
          },
          "update:is-show": function ($event) {
            _vm.promotionGoodsVisible = $event
          },
          "update:backFill": function ($event) {
            _vm.promotionGoods = $event
          },
          "update:back-fill": function ($event) {
            _vm.promotionGoods = $event
          },
          callback: _vm.confirmGoodsSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "商品名称" },
                  model: {
                    value: _vm.searchParams.productName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "productName", $$v)
                    },
                    expression: "searchParams.productName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "商家名称" },
                      model: {
                        value: _vm.searchParams.busId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "busId", $$v)
                        },
                        expression: "searchParams.busId",
                      },
                    },
                    "v-select2",
                    _vm.busIdParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "门店名称",
                        subjoin: _vm.storeExtra,
                        disabled: !_vm.searchParams.busId,
                      },
                      model: {
                        value: _vm.searchParams.storeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "storeId", $$v)
                        },
                        expression: "searchParams.storeId",
                      },
                    },
                    "v-select2",
                    _vm.storeParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }